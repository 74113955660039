import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@capturum/ui/api';
import { BuildersForm } from '@core/enums/builders.enum';
import { CapturumFormRendererModule, FormSaverService } from '@capturum/builders/form-renderer';
import { CommonModule } from '@angular/common';
import { CapturumButtonModule } from '@capturum/ui/button';
import { DoubleClickDirective } from '@shared/directives/double-click.directive';
import { SharedModule } from '@shared/shared.module';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogLayoutComponent } from '@shared/dialog-layout/dialog-layout.component';
import { ListRendererService } from '@capturum/builders/list-renderer';

@Component({
  selector: 'app-report-form',
  standalone: true,
  imports: [
    CommonModule,
    CapturumFormRendererModule,
    CapturumButtonModule,
    DoubleClickDirective,
    SharedModule,
    DialogLayoutComponent,
  ],
  templateUrl: './report-form.component.html',
  styleUrls: ['./report-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReportFormComponent implements OnInit {
  public modelId: string;
  public buildersFormKey = BuildersForm.report;

  constructor(
    public translateService: TranslateService,
    public route: ActivatedRoute,
    private formSaverService: FormSaverService,
    private router: Router,
    private toastService: ToastService,
    public config: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private listRendererService: ListRendererService,
  ) {}

  public ngOnInit(): void {
    this.modelId = this.config?.data?.item?.id;
  }

  public submit(): void {
    this.formSaverService.submit(this.buildersFormKey).subscribe(() => {
      this.toastService.success(
        this.translateService.instant('toast.success.title'),
        this.translateService.instant('mdn.report.save.success'),
      );

      this.listRendererService.refreshTable();
      this.cancel();
    });
  }

  public cancel(): void {
    this.dialogRef.close();
  }
}
