<app-dialog-layout>
  <ng-container content>
    <cpb-form-renderer [formKey]="buildersFormKey" [showHeader]="false" [modelId]="modelId"> </cpb-form-renderer>
  </ng-container>

  <ng-container buttons>
    <cap-button icon="fa fa-ban" [label]="'button.cancel' | translate" (onClick)="cancel()" styleClass="secondary">
    </cap-button>

    <cap-button
      appPreventDoubleClick
      icon="fas fa-check"
      [label]="'button.submit' | translate"
      (clickEvent)="submit()"
      styleClass="primary">
    </cap-button>
  </ng-container>
</app-dialog-layout>
